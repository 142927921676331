<template>
  <v-container>
    <!-- <p class="display-1 text--primary">
      <v-icon class="mr-2">mdi-cog</v-icon>Parâmetros dos Processos
    </p> -->

    <ConsultaProcessos />
  </v-container>
</template>
<script>
import { mapActions } from "vuex";
import ConsultaProcessos from "@/components/sistema/parametros/ConsultaProcessos";

export default {
  name: "ParametrosProcessos",
  components: {
    ConsultaProcessos
  },

  methods: {
    ...mapActions({
      fetchView: "sistema/fetchView"
    })
  },

  mounted() {
    this.fetchView("Consulta Parâmetros Processos");
  }
};
</script>

<style></style>
