<template>
  <v-dialog v-model="dialog" persistent max-width="50%">
    <v-card>
      <v-card-title class="pa-0">
        <v-toolbar class="toolbar-color">
          <span class="headline ml-2">
            {{
              labelBtn != "Salvar" ? "Editar Parâmetro" : "Cadastrar Parâmetro"
            }}
          </span>
        </v-toolbar>
      </v-card-title>
      <v-card-text>
        <v-container>
          <template>
            <v-form ref="form">
              <v-row>
                <v-col
                  v-if="labelBtn === 'Salvar'"
                  cols="12"
                  sm="6"
                  md="3"
                  lg="6"
                >
                  <v-select
                    :items="processosNaoAtivos"
                    v-model="formParametro.id_processo"
                    item-text="descricao"
                    item-value="id_processo"
                    label="Processos não Ativos"
                    :rules="[rules.required]"
                  />
                </v-col>

                <v-col v-else cols="12" sm="6" md="3" lg="6">
                  <v-text-field
                    label="Processo"
                    :rules="[rules.required]"
                    v-model="formParametro.descricao"
                    disabled
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="3" lg="4">
                  <v-select
                    :items="proc"
                    v-model="formParametro.id_param_proc"
                    item-text="formula"
                    item-value="id_param_proc"
                    label="Fórmula"
                    :disabled="labelBtn === 'Editar'"
                    :rules="[rules.required]"
                  />
                </v-col>

                <v-col cols="12" sm="6" md="3" lg="2">
                  <v-text-field
                    label="Ordem"
                    :rules="[rules.required]"
                    v-model="formParametro.ordem"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </template>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red darken-1" text @click="close">Fechar</v-btn>
        <v-btn
          color="blue darken-1"
          text
          @click="
            labelBtn != 'Salvar' ? editarParametro() : cadastrarParametro()
          "
          >{{ labelBtn }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import rules from "@/mixins/inputRules";
import serviceProcedure from "@/services/procedures";
export default {
  name: "ModalCadastroParametros",

  mixins: [rules],

  props: {
    dialog: {
      type: Boolean,
      default: false
    },

    labelBtn: {
      type: String
    },

    editParametro: {
      type: Object
    }
  },

  data() {
    return {
      processosNaoAtivos: [],
      proc: [],

      formParametro: {
        id_processo: "",
        descricao: "",
        id_param_proc: "",
        id_param: "",
        formula: "",
        ordem: ""
      }
    };
  },

  mounted() {
    this.fetchProcessosNaoAtivo();
    this.fetchProc();
  },

  watch: {
    dialog(value) {
      return value;
    },

    labelBtn(value) {
      return value;
    },

    editParametro() {
      if (this.editParametro) {
        this.formParametro = this.editParametro;
        this.formParametro.id_param_proc = Number(
          this.editParametro.id_param_proc
        );
      }
    }
  },

  methods: {
    async cadastrarParametro() {
      const validateStatus = this.$refs.form.validate();

      if (validateStatus) {
        try {
          await serviceProcedure.createParametros({
            id_processo: Number(this.formParametro.id_processo),
            ordem: Number(this.formParametro.ordem),
            id_param_proc: Number(this.formParametro.id_param_proc)
          }),
            this.$notify({
              type: "success",
              text: "Processo adicionado"
            });
          this.$emit("Refetch");
        } catch (error) {
          this.$notify({
            type: "error",
            text: "Erro ao adicionar Processo"
          });
          throw new Error(error);
        } finally {
          this.close();
        }
      } else {
        this.notify({ type: "error", text: "Formulário inválido" });
        throw new Error("Formulário inválido");
      }
    },

    async editarParametro() {
      const validateStatus = this.$refs.form.validate();

      if (validateStatus) {
        try {
          await serviceProcedure.putParametros(
            this.formParametro.id_processo_param,
            {
              ordem: Number(this.formParametro.ordem)
            }
          ),
            this.$notify({
              type: "success",
              text: "Parâmetro editado"
            });
          this.$emit("Refetch", this.formParametro.id_processo);
        } catch (error) {
          this.$notify({
            type: "error",
            text: "Erro ao editar Parâmetro"
          });
          throw new Error(error);
        } finally {
          this.close();
        }
      } else {
        this.notify({ type: "error", text: "Formulário inválido" });
        throw new Error("Formulário inválido");
      }
    },

    close() {
      this.$refs.form.reset();
      this.$emit("close");
    },

    async fetchProcessosNaoAtivo() {
      try {
        const { data } = await serviceProcedure.getProcessos({
          per_page: -1,
          ativo: "N"
        });
        this.processosNaoAtivos = data.data;
      } catch {
        this.$notify({
          type: "error",
          text: "Erro ao buscar Processos"
        });
      }
    },

    async fetchProc() {
      try {
        const { data } = await serviceProcedure.getProc();

        this.proc = data.data;
      } catch {
        this.$notify({
          type: "error",
          text: "Erro ao buscar Processos"
        });
      }
    }
  }
};
</script>

<style></style>
