<template>
  <div>
    <BaseTable
      class="mt-5"
      :items="parametros"
      :headers="headers"
      sort-by="id_processo"
      :loading="loading"
      :paginate="true"
      :search="search"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Processos</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Pesquisar"
            single-line
            hide-details
          ></v-text-field>
          <v-spacer> </v-spacer>

          <v-btn
            v-can-access="172"
            color="primary"
            dark
            @click="(dialog = true), (labelBtn = 'Salvar')"
          >
            <v-icon class="mr-1">mdi-plus</v-icon>
            Cadastrar
          </v-btn>
          <ModalCadastroParametros
            :dialog="dialog"
            :labelBtn="labelBtn"
            :editParametro="parametro"
            @close="dialog = false"
            @Refetch="fetchProcessos($event)"
          />
        </v-toolbar>
      </template>

      <template v-slot:[`item.descricao`]="{ item }">
        {{ item.descricao | TitleCase }}
      </template>
      <template v-slot:[`item.ordem`]="{ item }">
        {{ statusOrdem(item.ordem) }}
      </template>

      <template v-slot:[`item.acoes`]="{ item }">
        <v-btn
          icon
          v-can-access="170"
          @click="editItem(item)"
          color="orange"
          dark
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <v-btn
          v-can-access="173"
          icon
          @click="deleteItem(item)"
          class="ml-2"
          color="red"
          dark
          small
        >
          <v-icon>mdi-trash-can</v-icon>
        </v-btn>
      </template>
    </BaseTable>
  </div>
</template>

<script>
import dateMixin from "@/mixins/dateMixin";
import BaseTable from "@/components/shared/BaseTable.vue";
import ModalCadastroParametros from "@/components/sistema/parametros/ModalCadastroParametros";
import procedureService from "@/services/procedures";

export default {
  components: { BaseTable, ModalCadastroParametros },

  mixins: [dateMixin],

  data() {
    return {
      labelBtn: "Salvar",
      dialog: false,
      parametro: {},
      parametros: [],
      loading: false,
      search: "",
      headers: [
        { text: "ID Processo", value: "id_processo" },
        { text: "Processos", value: "descricao" },
        { text: "ID parametro-procedure", value: "id_param_proc" },
        { text: "ID parametro", value: "id_param" },
        { text: "Fórmula", value: "formula" },
        { text: "Ordem", value: "ordem" },
        { text: "Ações", value: "acoes", align: "center" }
      ]
    };
  },

  mounted() {
    this.fetchProcessos();
  },

  methods: {
    async fetchProcessos() {
      try {
        const { data } = await procedureService.getAllParametros({
          per_page: -1
        });
        this.parametros = data.data;
      } catch {
        this.$notify({
          type: "error",
          text: "Erro ao buscar os Processos"
        });
      }
    },

    statusOrdem(item) {
      if (item == null || item == "null      ") return "Não informado";
      return item;
    },

    editItem(item) {
      this.parametro = Object.assign({}, item);
      this.labelBtn = "Editar";
      this.dialog = true;
    },

    async deleteItem(item) {
      try {
        await this.$root.dialog({
          type: "warning",
          title: "Exclusão de Parâmetro",
          text: "Deseja deletar este Parâmetro?",
          confirmText: "deletar",
          rejectText: "Cancelar"
        });

        await procedureService.deleteParametros(item.id_processo_param);
        await this.fetchProcessos(item.id_processo);
        this.$notify({
          type: "success",
          text: "Processo deletado"
        });
      } catch (error) {
        if (error.response) {
          this.$notify({
            type: "error",
            text: "Erro ao deletar Processo"
          });
        }
      }
    }
  }
};
</script>

<style></style>
